import React, { useState, useEffect, Component } from "react";
import { Link } from "gatsby";

const ytURL = "https://www.youtube.com/embed/1yFKr_kZw8k?autoplay=true";

const playPause = e => {

    let link = document.getElementById('playpause');

    if(link.classList.contains('pause')) {
        link.classList.add('play');
        link.classList.remove('pause');
    } else {
        link.classList.remove('play');
        link.classList.add('pause');
    }

    let link2 = document.getElementById('audioPlayer');

    if(link2.classList.contains('hidden')) {
        link2.classList.remove('hidden');
        document.querySelector( 'iframe').src = ytURL;
    } else {
        link2.classList.add('hidden');
        document.querySelector( 'iframe').src = "https://www.youtube.com/embed/1yFKr_kZw8k?muted=true";

    }

}


const PlayRadio = (props) => {




    return (
        <>
                <li onClick={playPause} id="playpause" className="pause">
                    <a href="#" className="playls" >
                        <svg width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="play">
                            <path d="M18 31.5C10.2825 31.5 4 25.2175 4 17.5C4 9.7825 10.2825 3.5 18 3.5C25.7175 3.5 32 9.7825 32 17.5C32 25.2175 25.7175 31.5 18 31.5ZM18 0C15.7019 0 13.4262 0.452651 11.303 1.33211C9.17984 2.21157 7.25066 3.50061 5.62563 5.12563C2.34374 8.40752 0.5 12.8587 0.5 17.5C0.5 22.1413 2.34374 26.5925 5.62563 29.8744C7.25066 31.4994 9.17984 32.7884 11.303 33.6679C13.4262 34.5474 15.7019 35 18 35C22.6413 35 27.0925 33.1563 30.3744 29.8744C33.6563 26.5925 35.5 22.1413 35.5 17.5C35.5 15.2019 35.0474 12.9262 34.1679 10.803C33.2884 8.67984 31.9994 6.75066 30.3744 5.12563C28.7493 3.50061 26.8202 2.21157 24.697 1.33211C22.5738 0.452651 20.2981 0 18 0V0ZM14.5 25.375L25 17.5L14.5 9.625V25.375Z" fill="white"/>
                        </svg>
                        <svg width="36" height="35" viewBox="0 0 81 81" version="1.1" xmlns="http://www.w3.org/2000/svg" className="pause playpause" >
                            <title>pause</title>
                            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="play" fill="#FFFFFF" fillRule="nonzero">
                                    <path d="M40.5,72.9 C22.6395,72.9 8.1,58.3605 8.1,40.5 C8.1,22.6395 22.6395,8.1 40.5,8.1 C58.3605,8.1 72.9,22.6395 72.9,40.5 C72.9,58.3605 58.3605,72.9 40.5,72.9 Z M40.5,0 C35.18154,0 29.91492,1.04756374 25.0012286,3.08288314 C20.0876297,5.11820486 15.622956,8.10141171 11.8621723,11.8621723 C4.26694114,19.4574034 0,29.7587057 0,40.5 C0,51.2412943 4.26694114,61.5426429 11.8621723,69.1378971 C15.622956,72.8986114 20.0876297,75.8817257 25.0012286,77.91714 C29.91492,79.9525543 35.18154,81 40.5,81 C51.2412943,81 61.5426429,76.7331514 69.1378971,69.1378971 C76.7331514,61.5426429 81,51.2412943 81,40.5 C81,35.18154 79.9525543,29.91492 77.91714,25.0012286 C75.8817257,20.0876297 72.8986114,15.622956 69.1378971,11.8621723 C65.3769514,8.10141171 60.9124629,5.11820486 55.9987714,3.08288314 C51.08508,1.04756374 45.81846,0 40.5,0 L40.5,0 Z M26.4571429,24.4571429 L38.0285714,24.4571429 L38.0285714,56.8571429 L26.4571429,56.8571429 L26.4571429,24.4571429 Z M42.6571429,24.4571429 L54.2285714,24.4571429 L54.2285714,56.8571429 L42.6571429,56.8571429 L42.6571429,24.4571429 Z" id="Shape"></path>
                                </g>
                            </g>
                        </svg>
                        <span>Play Reflections 24/7 Radio</span>

                    </a>
                </li>


        </>
    );

};

export default PlayRadio;
