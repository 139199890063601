import { doc } from "prettier";
import React, { Component } from "react";
import Select from "react-select";

function formSubmit(e) {
  if (
    document.querySelector('form input[name="email"]').value != "" &&
    document.querySelector('form input[name="firstname"]').value != ""
  ) {
    e.target.style.display = "none";
    document.getElementById("notification").classList.add("shownote");
    document
      .querySelector('form input[name="email"]')
      .classList.remove("error");
    document
      .querySelector('form input[name="firstname"]')
      .classList.remove("error");
  } else {
    e.preventDefault();
    if (document.querySelector('form input[name="email"]').value == "") {
      document.querySelector('form input[name="email"]').classList.add("error");
    }
    if (document.querySelector('form input[name="firstname"]').value == "") {
      document
        .querySelector('form input[name="firstname"]')
        .classList.add("error");
    }
  }
}



const handleFocus = (e) => {
  e.target.parentNode.classList.add("focusWithText");
};

const handleBlur = (e) => {
  if (e.target.value == "") {
    e.target.parentNode.classList.remove("focusWithText");
  }
};

const selectBlur = (e) => {
  let value = document.querySelector(
    ".field4select .react-select__single-value"
  ).innerHTML;
  if (value == "") {
    document.querySelector(".field4select").classList.remove("focusWithText");
  }
};

const selectFocus = (e) => {
  document.querySelector(".field4select").classList.add("focusWithText");
};

const selectClick = (e) => {
  if (e == "") {
    document.querySelector(".field4select").classList.remove("focusWithText");
  } else {
    document.querySelector(".field4select").classList.add("focusWithText");
  }
};

const selectChange = (e) => {
  if (e == "") {
    document.querySelector(".field4select").classList.remove("focusWithText");
  } else {
    document.querySelector(".field4select").classList.add("focusWithText");
  }
};

const options = [
  {
    value: "",
    label: "",
  },
  {
    value: "Afghanistan",
    label: "Afghanistan",
  },
  {
    value: "Albania",
    label: "Albania",
  },
  {
    value: "Algeria",
    label: "Algeria",
  },
  {
    value: "American Samoa",
    label: "American Samoa",
  },
  {
    value: "Andorra",
    label: "Andorra",
  },
  {
    value: "Angola",
    label: "Angola",
  },
  {
    value: "Anguilla",
    label: "Anguilla",
  },
  {
    value: "Antarctica",
    label: "Antarctica",
  },
  {
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },
  {
    value: "Armenia",
    label: "Armenia",
  },
  {
    value: "Aruba",
    label: "Aruba",
  },
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Austria",
    label: "Austria",
  },
  {
    value: "Azerbaijan",
    label: "Azerbaijan",
  },
  {
    value: "Bahamas",
    label: "Bahamas",
  },
  {
    value: "Bahrain",
    label: "Bahrain",
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
  },
  {
    value: "Barbados",
    label: "Barbados",
  },
  {
    value: "Belarus",
    label: "Belarus",
  },
  {
    value: "Belgium",
    label: "Belgium",
  },
  {
    value: "Belize",
    label: "Belize",
  },
  {
    value: "Benin",
    label: "Benin",
  },
  {
    value: "Bermuda",
    label: "Bermuda",
  },
  {
    value: "Bhutan",
    label: "Bhutan",
  },
  {
    value: "Bolivia",
    label: "Bolivia",
  },
  {
    value: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
  },
  {
    value: "Botswana",
    label: "Botswana",
  },
  {
    value: "Bouvet Island",
    label: "Bouvet Island",
  },
  {
    value: "Brazil",
    label: "Brazil",
  },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  {
    value: "Brunei Darussalam",
    label: "Brunei Darussalam",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
  },
  {
    value: "Burkina Faso",
    label: "Burkina Faso",
  },
  {
    value: "Burundi",
    label: "Burundi",
  },
  {
    value: "Cambodia",
    label: "Cambodia",
  },
  {
    value: "Cameroon",
    label: "Cameroon",
  },
  {
    value: "Canada",
    label: "Canada",
  },
  {
    value: "Cape Verde",
    label: "Cape Verde",
  },
  {
    value: "Cayman Islands",
    label: "Cayman Islands",
  },
  {
    value: "Central African Republic",
    label: "Central African Republic",
  },
  {
    value: "Chad",
    label: "Chad",
  },
  {
    value: "Chile",
    label: "Chile",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Christmas Island",
    label: "Christmas Island",
  },
  {
    value: "Cocos (Keeling) Islands",
    label: "Cocos (Keeling) Islands",
  },
  {
    value: "Colombia",
    label: "Colombia",
  },
  {
    value: "Comoros",
    label: "Comoros",
  },
  {
    value: "Congo",
    label: "Congo",
  },
  {
    value: "Congo, the Democratic Republic of the",
    label: "Congo, the Democratic Republic of the",
  },
  {
    value: "Cook Islands",
    label: "Cook Islands",
  },
  {
    value: "Costa Rica",
    label: "Costa Rica",
  },
  {
    value: "Cote D&#039;Ivoire",
    label: "Cote D'Ivoire",
  },
  {
    value: "Croatia",
    label: "Croatia",
  },
  {
    value: "Cuba",
    label: "Cuba",
  },
  {
    value: "Cyprus",
    label: "Cyprus",
  },
  {
    value: "Czech Republic",
    label: "Czech Republic",
  },
  {
    value: "Denmark",
    label: "Denmark",
  },
  {
    value: "Djibouti",
    label: "Djibouti",
  },
  {
    value: "Dominica",
    label: "Dominica",
  },
  {
    value: "Dominican Republic",
    label: "Dominican Republic",
  },
  {
    value: "Ecuador",
    label: "Ecuador",
  },
  {
    value: "Egypt",
    label: "Egypt",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
  },
  {
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
  },
  {
    value: "Eritrea",
    label: "Eritrea",
  },
  {
    value: "Estonia",
    label: "Estonia",
  },
  {
    value: "Ethiopia",
    label: "Ethiopia",
  },
  {
    value: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
  },
  {
    value: "Faroe Islands",
    label: "Faroe Islands",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Finland",
    label: "Finland",
  },
  {
    value: "France",
    label: "France",
  },
  {
    value: "French Guiana",
    label: "French Guiana",
  },
  {
    value: "French Polynesia",
    label: "French Polynesia",
  },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
  },
  {
    value: "Gabon",
    label: "Gabon",
  },
  {
    value: "Gambia",
    label: "Gambia",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "Ghana",
    label: "Ghana",
  },
  {
    value: "Gibraltar",
    label: "Gibraltar",
  },
  {
    value: "Greece",
    label: "Greece",
  },
  {
    value: "Greenland",
    label: "Greenland",
  },
  {
    value: "Grenada",
    label: "Grenada",
  },
  {
    value: "Guadeloupe",
    label: "Guadeloupe",
  },
  {
    value: "Guam",
    label: "Guam",
  },
  {
    value: "Guatemala",
    label: "Guatemala",
  },
  {
    value: "Guinea",
    label: "Guinea",
  },
  {
    value: "Guinea-Bissau",
    label: "Guinea-Bissau",
  },
  {
    value: "Guyana",
    label: "Guyana",
  },
  {
    value: "Haiti",
    label: "Haiti",
  },
  {
    value: "Heard Island and Mcdonald Islands",
    label: "Heard Island and Mcdonald Islands",
  },
  {
    value: "Holy See (Vatican City State)",
    label: "Holy See (Vatican City State)",
  },
  {
    value: "Honduras",
    label: "Honduras",
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
  },
  {
    value: "Hungary",
    label: "Hungary",
  },
  {
    value: "Iceland",
    label: "Iceland",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "Indonesia",
    label: "Indonesia",
  },
  {
    value: "Iran, Islamic Republic of",
    label: "Iran, Islamic Republic of",
  },
  {
    value: "Iraq",
    label: "Iraq",
  },
  {
    value: "Ireland",
    label: "Ireland",
  },
  {
    value: "Israel",
    label: "Israel",
  },
  {
    value: "Italy",
    label: "Italy",
  },
  {
    value: "Jamaica",
    label: "Jamaica",
  },
  {
    value: "Japan",
    label: "Japan",
  },
  {
    value: "Jordan",
    label: "Jordan",
  },
  {
    value: "Kazakhstan",
    label: "Kazakhstan",
  },
  {
    value: "Kenya",
    label: "Kenya",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
  },
  {
    value: "Korea, Democratic People&#039;s Republic of",
    label: "Korea, Democratic People's Republic of",
  },
  {
    value: "Korea, Republic of",
    label: "Korea, Republic of",
  },
  {
    value: "Kuwait",
    label: "Kuwait",
  },
  {
    value: "Kyrgyzstan",
    label: "Kyrgyzstan",
  },
  {
    value: "Lao People&#039;s Democratic Republic",
    label: "Lao People's Democratic Republic",
  },
  {
    value: "Latvia",
    label: "Latvia",
  },
  {
    value: "Lebanon",
    label: "Lebanon",
  },
  {
    value: "Lesotho",
    label: "Lesotho",
  },
  {
    value: "Liberia",
    label: "Liberia",
  },
  {
    value: "Libyan Arab Jamahiriya",
    label: "Libyan Arab Jamahiriya",
  },
  {
    value: "Liechtenstein",
    label: "Liechtenstein",
  },
  {
    value: "Lithuania",
    label: "Lithuania",
  },
  {
    value: "Luxembourg",
    label: "Luxembourg",
  },
  {
    value: "Macao",
    label: "Macao",
  },
  {
    value: "Macedonia, the Former Yugoslav Republic of",
    label: "Macedonia, the Former Yugoslav Republic of",
  },
  {
    value: "Madagascar",
    label: "Madagascar",
  },
  {
    value: "Malawi",
    label: "Malawi",
  },
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Maldives",
    label: "Maldives",
  },
  {
    value: "Mali",
    label: "Mali",
  },
  {
    value: "Malta",
    label: "Malta",
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
  },
  {
    value: "Martinique",
    label: "Martinique",
  },
  {
    value: "Mauritania",
    label: "Mauritania",
  },
  {
    value: "Mauritius",
    label: "Mauritius",
  },
  {
    value: "Mayotte",
    label: "Mayotte",
  },
  {
    value: "Mexico",
    label: "Mexico",
  },
  {
    value: "Micronesia, Federated States of",
    label: "Micronesia, Federated States of",
  },
  {
    value: "Moldova, Republic of",
    label: "Moldova, Republic of",
  },
  {
    value: "Monaco",
    label: "Monaco",
  },
  {
    value: "Mongolia",
    label: "Mongolia",
  },
  {
    value: "Montserrat",
    label: "Montserrat",
  },
  {
    value: "Morocco",
    label: "Morocco",
  },
  {
    value: "Mozambique",
    label: "Mozambique",
  },
  {
    value: "Myanmar",
    label: "Myanmar",
  },
  {
    value: "Namibia",
    label: "Namibia",
  },
  {
    value: "Nauru",
    label: "Nauru",
  },
  {
    value: "Nepal",
    label: "Nepal",
  },
  {
    value: "Netherlands",
    label: "Netherlands",
  },
  {
    value: "Netherlands Antilles",
    label: "Netherlands Antilles",
  },
  {
    value: "New Caledonia",
    label: "New Caledonia",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
  },
  {
    value: "Nicaragua",
    label: "Nicaragua",
  },
  {
    value: "Niger",
    label: "Niger",
  },
  {
    value: "Nigeria",
    label: "Nigeria",
  },
  {
    value: "Niue",
    label: "Niue",
  },
  {
    value: "Norfolk Island",
    label: "Norfolk Island",
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
  },
  {
    value: "Norway",
    label: "Norway",
  },
  {
    value: "Oman",
    label: "Oman",
  },
  {
    value: "Pakistan",
    label: "Pakistan",
  },
  {
    value: "Palau",
    label: "Palau",
  },
  {
    value: "Palestinian Territory, Occupied",
    label: "Palestinian Territory, Occupied",
  },
  {
    value: "Panama",
    label: "Panama",
  },
  {
    value: "Papua New Guinea",
    label: "Papua New Guinea",
  },
  {
    value: "Paraguay",
    label: "Paraguay",
  },
  {
    value: "Peru",
    label: "Peru",
  },
  {
    value: "Philippines",
    label: "Philippines",
  },
  {
    value: "Pitcairn",
    label: "Pitcairn",
  },
  {
    value: "Poland",
    label: "Poland",
  },
  {
    value: "Portugal",
    label: "Portugal",
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
  },
  {
    value: "Qatar",
    label: "Qatar",
  },
  {
    value: "Reunion",
    label: "Reunion",
  },
  {
    value: "Romania",
    label: "Romania",
  },
  {
    value: "Russian Federation",
    label: "Russian Federation",
  },
  {
    value: "Rwanda",
    label: "Rwanda",
  },
  {
    value: "Saint Helena",
    label: "Saint Helena",
  },
  {
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
  },
  {
    value: "Saint Lucia",
    label: "Saint Lucia",
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "Samoa",
    label: "Samoa",
  },
  {
    value: "San Marino",
    label: "San Marino",
  },
  {
    value: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
  },
  {
    value: "Saudi Arabia",
    label: "Saudi Arabia",
  },
  {
    value: "Senegal",
    label: "Senegal",
  },
  {
    value: "Serbia and Montenegro",
    label: "Serbia and Montenegro",
  },
  {
    value: "Seychelles",
    label: "Seychelles",
  },
  {
    value: "Sierra Leone",
    label: "Sierra Leone",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
  {
    value: "Slovakia",
    label: "Slovakia",
  },
  {
    value: "Slovenia",
    label: "Slovenia",
  },
  {
    value: "Solomon Islands",
    label: "Solomon Islands",
  },
  {
    value: "Somalia",
    label: "Somalia",
  },
  {
    value: "South Africa",
    label: "South Africa",
  },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "Spain",
    label: "Spain",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
  },
  {
    value: "Sudan",
    label: "Sudan",
  },
  {
    value: "Suriname",
    label: "Suriname",
  },
  {
    value: "Svalbard and Jan Mayen",
    label: "Svalbard and Jan Mayen",
  },
  {
    value: "Swaziland",
    label: "Swaziland",
  },
  {
    value: "Sweden",
    label: "Sweden",
  },
  {
    value: "Switzerland",
    label: "Switzerland",
  },
  {
    value: "Syrian Arab Republic",
    label: "Syrian Arab Republic",
  },
  {
    value: "Taiwan, Province of China",
    label: "Taiwan, Province of China",
  },
  {
    value: "Tajikistan",
    label: "Tajikistan",
  },
  {
    value: "Tanzania, United Republic of",
    label: "Tanzania, United Republic of",
  },
  {
    value: "Thailand",
    label: "Thailand",
  },
  {
    value: "Timor-Leste",
    label: "Timor-Leste",
  },
  {
    value: "Togo",
    label: "Togo",
  },
  {
    value: "Tokelau",
    label: "Tokelau",
  },
  {
    value: "Tonga",
    label: "Tonga",
  },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
  },
  {
    value: "Tunisia",
    label: "Tunisia",
  },
  {
    value: "Turkey",
    label: "Turkey",
  },
  {
    value: "Turkmenistan",
    label: "Turkmenistan",
  },
  {
    value: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
  },
  {
    value: "Uganda",
    label: "Uganda",
  },
  {
    value: "Ukraine",
    label: "Ukraine",
  },
  {
    value: "United Arab Emirates",
    label: "United Arab Emirates",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
  },
  {
    value: "United States",
    label: "United States",
  },
  {
    value: "United States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
  },
  {
    value: "Uzbekistan",
    label: "Uzbekistan",
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
  },
  {
    value: "Venezuela",
    label: "Venezuela",
  },
  {
    value: "Viet Nam",
    label: "Viet Nam",
  },
  {
    value: "Virgin Islands, British",
    label: "Virgin Islands, British",
  },
  {
    value: "Virgin Islands, U.s.",
    label: "Virgin Islands, U.s.",
  },
  {
    value: "Wallis and Futuna",
    label: "Wallis and Futuna",
  },
  {
    value: "Western Sahara",
    label: "Western Sahara",
  },
  {
    value: "Yemen",
    label: "Yemen",
  },
  {
    value: "Zambia",
    label: "Zambia",
  },
  {
    value: "Zimbabwe",
    label: "Zimbabwe",
  },
  {
    value: "Bosina",
    label: "Bosina",
  },
  {
    value: "Guernsey",
    label: "Guernsey",
  },
  {
    value: "Australis",
    label: "Australis",
  },
  {
    value: "Iran",
    label: "Iran",
  },
  {
    value: "Isle of Man",
    label: "Isle of Man",
  },
  {
    value: "Jersey  (Channel Islands)",
    label: "Jersey  (Channel Islands)",
  },
  {
    value: "South Korea",
    label: "South Korea",
  },
  {
    value: "Macedonia",
    label: "Macedonia",
  },
  {
    value: "Moldavia",
    label: "Moldavia",
  },
  {
    value: "Montenegro",
    label: "Montenegro",
  },
  {
    value: "Russia",
    label: "Russia",
  },
  {
    value: "Serbia",
    label: "Serbia",
  },
  {
    value: "Syria",
    label: "Syria",
  },
  {
    value: "Taiwan",
    label: "Taiwan",
  },
  {
    value: "Vietnam",
    label: "Vietnam",
  },
  {
    value: "Virgin Islands (British)",
    label: "Virgin Islands (British)",
  },
  {
    value: "Afghanistan, Islamic State of",
    label: "Afghanistan, Islamic State of",
  },
  {
    value: "French Guyana",
    label: "French Guyana",
  },
  {
    value: "Laos",
    label: "Laos",
  },
  {
    value: "Slovak Republic",
    label: "Slovak Republic",
  },
  {
    value: "Andorra, Principality of",
    label: "Andorra, Principality of",
  },
  {
    value: "Azerbaidjan",
    label: "Azerbaidjan",
  },
  {
    value: "Bosnia-Herzegovina",
    label: "Bosnia-Herzegovina",
  },
  {
    value: "Cambodia, Kingdom of",
    label: "Cambodia, Kingdom of",
  },
  {
    value: "Guam (USA)",
    label: "Guam (USA)",
  },
  {
    value: "New Caledonia (French)",
    label: "New Caledonia (French)",
  },
  {
    value: "PARAGUAY",
    label: "PARAGUAY",
  },
  {
    value: "Reunion (French)",
    label: "Reunion (French)",
  },
  {
    value: "Saint Kitts &amp; Nevis Anguilla",
    label: "Saint Kitts & Nevis Anguilla",
  },
  {
    value: "Tanzania",
    label: "Tanzania",
  },
  {
    value: "Virgin Islands (USA)",
    label: "Virgin Islands (USA)",
  },
  {
    value: "Libya",
    label: "Libya",
  },
  {
    value: "Macau",
    label: "Macau",
  },
  {
    value: "Jersey",
    label: "Jersey",
  },
];

class Form2 extends Component {

  constructor(props) {
    super(props);
    // Initialize the state
    this.state = {
      isVisible: true,
    };

    // Binding this to function
    this.hidePopup = this.hidePopup.bind(this);
  }


   hidePopup() {
    document.querySelector('.urlform').style.display = "none";
    document.querySelector('.urlform2').style.display = "none";
  }

  render() {

    if (!this.state.isVisible) {
      return null;
    }

    return (
      <div className={`urlform2 ${this.props.hideForm ? "hideform" : ""}`}>
        <div className="gradient"></div>
        <div className="form ">
          <div className="x" onClick={this.hidePopup}>
            <svg
              className="w-3 h-3"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              <polygon
                points="16 13.74 13.74 16 8 10.26 2.26 16 0 13.74 5.74 8 0 2.26 2.26 0 8 5.74 13.74 0 16 2.26 10.26 8 16 13.74"
                fill="#fff"
              ></polygon>
            </svg>
          </div>
          <div id="notification" className="notif">
            {this.props.hideForm ? (
              <>
                <p className="dyon">
                  Subscription success<br />
                  <br />
                  You are now subscribed to the Reflections mailing list.
                </p>
                <p className="twon">
              Thanks for signing up! Click <a
                href="https://soundcloud.com/anjunadeepreflections/sets/croquet-club-fugue-in-twilight/s-Nd6lHRx83jd?si=4ec3564b388d4da392d05348c0cd7157&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
                target="_blank"
              >here</a> to access exclusive previews of Croquet Club's new album.
            </p>
                <br />
                <p className="ok" onClick={this.hidePopup}>
                  <span>OK</span>
                </p>
              </>
            ) : (
              <p>
                Thanks for signing up! Please check your email to confirm your
                subscription.
              </p>
            )}
          </div>

          <form
            onSubmit={formSubmit}
            method="POST"
            action="https://anjuna.activehosted.com/proc.php"
            id="_form_50_"
            className="_form _form_50 _inline-form  _dark"
            noValidate
          >
            <input type="hidden" name="u" value="50" />
            <input type="hidden" name="f" value="50" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />
            <input
              type="hidden"
              name="or"
              value="fc9a36f4f9fcfa6d0bc59ec59217013d"
            />

            <div className="field4">
              <input
                type="text"
                id="firstname"
                name="firstname"
                data-name="firstname"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />

              <p className="helper helper1">Name</p>
            </div>
            <div className="field4">
              <input
                type="text"
                id="email"
                name="email"
                required=""
                data-name="email"
                onFocus={handleFocus}
                onBlur={handleBlur}
              />

              <p className="helper helper1">Email</p>
            </div>

            <div className="field4select">
              <Select
                placeholder=""
                options={options}
                className="react-select-container"
                classNamePrefix="react-select"
                name="field[7]"
                id="field[7]"
                onFocus={selectFocus}
                onChange={selectClick}
                onBlur={selectBlur}
              />
              <p className="helper helper1">Country</p>
            </div>

            <input id="submit" type="submit" value="Subscribe" />
          </form>
        </div>
      </div>
    );
  }
}

export default Form2;
