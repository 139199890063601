import * as React from "react";
import ScrollAnimation from 'react-animate-on-scroll';

import release1 from "../img/release1.png";
import release2 from "../img/release2.png";


const Releases = class extends React.Component {
  render() {
    return (
      <>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>

        <div id="moodMixes">
            <div className="title">Releases</div>
            <div className="introText">
                <div>Here is some text about the releases</div>
            </div>
            <div className="releaseContent">

            <div className="mix">
                <img src={release1} />
                <p>Compuphonic</p>
                <p>Aquamarine/Fairfax</p>
            </div>
            <div className="mix">
                <img src={release2} />
                <p>Yotto</p>
                <p>Night Walk</p>
            </div>
            <div className="mix">
                <img src={release1} />
                <p>Compuphonic</p>
                <p>Aquamarine/Fairfax</p>
            </div>
            <div className="mix">
                <img src={release2} />
                <p>Yotto</p>
                <p>Night Walk</p>
            </div>
            <div className="mix">
                <img src={release1} />
                <p>Compuphonic</p>
                <p>Aquamarine/Fairfax</p>
            </div>
            <div className="mix">
                <img src={release2} />
                <p>Yotto</p>
                <p>Night Walk</p>
            </div>
            <div className="mix">
                <img src={release1} />
                <p>Compuphonic</p>
                <p>Aquamarine/Fairfax</p>
            </div>
            <div className="mix">
                <img src={release2} />
                <p>Yotto</p>
                <p>Night Walk</p>
            </div>

            </div>
        </div>
        </ScrollAnimation>

      </>
    );
  }
};

export default Releases;
