import * as React from "react";


const Artwork = class extends React.Component {
  render() {
    return (
      <>
      <div className="reflectionsContent">

      <section id="playlistsContainer">
        <div className="title">{this.props.title}</div>
        <div className="introText">
          <div>{this.props.subtitle}</div>
          <div>{this.props.description}</div>
        </div>
        <div className="artwork">
            <div className="horizonGradient"></div>
            <a href={this.props.playlistURL1} target="_blank">
              <img src={this.props.playlistImg1.childImageSharp.fluid.src}/>
            </a>
            <a href={this.props.playlistURL2} target="_blank">
              <img src={this.props.playlistImg2.childImageSharp.fluid.src}/>
            </a>
            <a href={this.props.playlistURL3} target="_blank">
              <img src={this.props.playlistImg3.childImageSharp.fluid.src}/>
            </a>
        </div>
      </section>
      </div>
      </>
    );
  }
};

export default Artwork;
