import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import ScrollAnimation from 'react-animate-on-scroll';

import Layout from "../components/Layout";
import Logo from "../components/Logo";
import Artwork from "../components/Artwork";
import Releases from "../components/Releases";
// import MoodMixes from "../components/MoodMixes";
import PlayRadio from "../components/PlayRadio";

import FocusVideo from "../img/Focus.mp4";
import RelaxVideo from "../img/Relax.mp4";
import SleepVideo from "../img/Sleep.mp4";
import MoodsVideo from "../img/Moods.mp4";
import HomeReleaseRoll from "../components/HomeReleaseRoll";
import TwilightVideo from "../img/twilight.mp4";
import Form2 from "../components/Form2";
import URLForm2 from "../components/URLForm2";

let videoList = [FocusVideo, RelaxVideo, SleepVideo];

function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}


// eslint-disable-next-line
export const IndexPageTemplate = ({
  title,
  heading,
  subheading,
  playlistTitle,
  playlistSubTitle,
  playlistDescription,
}) => {


  const [showModal, setShowModal] = useState(false);

  let openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    document.getElementById("notification").classList.remove("shownote");
  };

  const [showModalURL, setShowModalURL] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(function mount() {
    if (
      window.location.hash == "#signup" ||
      window.location.hash == "#success"
    ) {
      setShowModalURL(true);
    }
    if (window.location.hash == "#success") {
      setShowForm(true);
    }
  });

  return (
    <>

      <div className="reflections">
        <div className="hero">
          <div className="overlay"></div>

          <video autoPlay loop muted playsInline className="dayvideo">
            <source src={MoodsVideo}></source>
          </video>
          <video autoPlay loop muted playsInline className="twvideo">
            {/* <source src={videoList[randomNumber(0, videoList.length)]}></source> */}
            <source src={TwilightVideo}></source>
          </video>
          <div className="heroContent">
            <Logo />
            <div className="heroText">
              <h1><span className="daylighttext">{heading}</span><span className="twilighttext">Welcome to Reflections Twilight Mode</span></h1>
              {/* <p>Sign up for our newsletter.</p> */}
              <div id="line"></div>
              <PlayRadio />
              <li id="showform2" className="pause">
              <span>Enter your email below to unlock previews<br/> of Croquet Club’s new album 'Fugue In Twilight'.</span>


<br/><br/>
              <div className="ytsubscribe"><span onClick={openModal}>Subscribe</span></div>
              </li>
              <a className="yt" href="https://www.youtube.com/channel/UCV8pMjLasWLdoANBOICgahw" target="_blank"><span>Subscribe on Youtube</span></a>
            </div>
          </div>
        </div>

        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          {/* <Artwork
            title={playlistTitle}
            subtitle={playlistSubTitle}
            description={playlistDescription}
            //   playlistImg1={playlistimage1}
            //   playlistURL1={playlisturl1}
            //   playlistImg2={playlistimage2}
            //   playlistURL2={playlisturl2}
            //   playlistImg3={playlistimage3}
            //   playlistURL3={playlisturl3}
             /> */}
        </ScrollAnimation>

        {/* <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <MoodMixes />
        </ScrollAnimation> */}


        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div id="home-releases">
            <HomeReleaseRoll />
          </div>
        </ScrollAnimation>

      </div>

      {showModal ? (
        <Form2 setShowModal={showModal} shouldClose={closeModal} />
      ) : null}
      {showModalURL ? (
        <URLForm2
          setShowModal={showModal}
          shouldClose={closeModal}
          hideForm={showForm}
        />
      ) : null}

    </>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        playlistTitle={frontmatter.playlistTitle}
        playlistSubTitle={frontmatter.playlistSubTitle}
        playlistDescription={frontmatter.playlistDescription}
      />
    </Layout>

  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        playlistTitle
        playlistSubTitle
        playlistDescription
      }
    }
  }
`;



